<template>
  <div id="app" class="h-100" :class="[skinClasses]">
    <component :is="layout">
      <router-view />
    </component>

    <scroll-to-top v-if="enableScrollToTop" />
    <div id="developerTools">
      <b-modal
        id="developer-tools"
        hide-footer
        v-model="developerTools"
        modal-class="modal"
        centered
      >
        <template v-slot:modal-title>
          <i class="feather icon-terminal"></i>
          <b> Developer Tools - Click Any Value To Copy</b>
        </template>
        <div class="flex justify-between items-center mb-2">
          <h4><i class="feather icon-user"></i> Full name</h4>
          <h4 @click="copyText(userInfo.name)">{{ userInfo.name }}</h4>
        </div>
        <div class="flex justify-between items-center mb-2">
          <h4><i class="feather icon-hash"></i> User ID</h4>
          <h4 @click="copyText(userInfo.id)">{{ userInfo.id }}</h4>
        </div>
        <div class="flex justify-between items-center mb-2">
          <h4><i class="feather icon-mail"></i> Email Address</h4>
          <h4 @click="copyText(userInfo.email)"><i class="feather icon-email"></i> {{ userInfo.email }}</h4>
        </div>
        <div class="flex justify-between">
          <div class="flex items-center">
            <b-button class="copyButton" @click="copyText('accessToken')"><i class="feather icon-copy"></i> Access</b-button>
            <b-button class="ml-1 mr-1 copyButton" @click="copyText('refreshToken')"><i class="feather icon-copy"></i> Refresh</b-button>
            <b-button class="copyButton" @click="copyText(userInfo.permissions)"><i class="feather icon-copy"></i> Perms</b-button>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue';

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig';
import { provideToast } from 'vue-toastification/composition';
import { watch } from '@vue/composition-api';
import useAppConfig from '@core/app-config/useAppConfig';

import { useWindowSize, useCssVar } from '@vueuse/core';

import store from '@/store';
import { BButton, BCardText, BModal } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { FeatherIcon } from "vue-feather-icons";

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue');
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue');
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue');

export default {
  components: {
    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,
    BModal,
    BCardText,
    BButton,
    FeatherIcon,


    ScrollToTop,
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full';
      return `layout-${this.contentLayoutType}`;
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type;
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        let isStandalone = false;
        if (window.matchMedia('(display-mode: standalone)').matches) {
          isStandalone = true;
        }

        //document.title = (to.meta.pageTitle || 'App') + (isStandalone ? '' : ` - Management`);
      },
    },
  },
  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark'];

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim();
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl'];

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(
        useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2)
      );
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout;
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr');
  },
  created() {
    if (this.$workbox) {
      this.$workbox.addEventListener('waiting', () => {
        this.showUpdateUI = true;

      });
    }
    if (this.$can('user.update')) {
      window.addEventListener('keydown', this.developerMode)
    }
    //fixme: zoom disable  document.body.style.zoom = "90%";
  },
  mounted() {
    const userData = JSON.parse(localStorage.getItem('userData'));

    if (userData && userData.name) this.userInfo = userData;

    if (process.env.VUE_APP_ENABLE_TAWK_TO && false) {
      let recaptchaScript = document.createElement('script');
      recaptchaScript.setAttribute('src', 'https://embed.tawk.to/6151bd3125797d7a89010788/1fgjkm281');
      document.head.appendChild(recaptchaScript);
    }

    window.Tawk_API = window.Tawk_API || {};
    window.Tawk_LoadStart = new Date();

    if (window.Tawk_API && userData && userData.name) {
      window.Tawk_API.onLoad = () => {
        window.Tawk_API.setAttributes({
          name: userData.name,
          email: userData.email,
          role: userData.role,
          username: userData.username,
          avatar: userData.avatar,
        });
      };
    }
  },
  methods: {
    developerMode (e) {
      if (e.ctrlKey && e.shiftKey && e.keyCode === 'D'.charCodeAt(0)) {
        e.preventDefault()
        this.developerTools = !this.developerTools
      }
    },
    copyText (x) {
      let text = x
      if (x === 'accessToken' || x === 'refreshToken') {
        const token = localStorage.getItem(x)
        text = token ? token : ''
      }
      clipboard.writeText(text)
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Value Copied!',
          icon: 'InfoIcon',
          variant: 'success',
        },
      })
    },
  },
  data() {
    return {
      developerTools: false,
      userInfo: {}
    }
  },
  setup() {
    const { skin, skinClasses } = useAppConfig();
    const { enableScrollToTop } = $themeConfig.layout;

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout');

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    });

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth);
    const { width: windowWidth } = useWindowSize();
    watch(windowWidth, (val) => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val);
    });

    return {
      skinClasses,
      enableScrollToTop,
    };
  },
  metaInfo: {
    title: 'Management',
    titleTemplate: '%s - Management',
  },
};
</script>
